import styles from './index.module.scss';
import React from 'react';


export default function ProductGoodness() {
    return (
        <div className={styles.ProductGoodness} id='ProductGoodness'>
            <div className={styles.productTitle}>
                <img className={styles.productGoodness} src={require('./img/productGoodness.png')}/>
            </div>
            <div className={styles.productCard}>
                <div className={styles.cardDiv}>
                    <img className={styles.cardIcon} src={require('./img/ysy.png')}/>
                    <div className={styles.cardTitle}>精准全</div>
                    <div className={styles.cardDetail}>
                        平台涵盖了老挝各地区的地产信息，包括最新地产信息、价格走势、政策变动等，为投资者提供全面的市场概览。
                    </div>
                </div>
                <div className={styles.cardDiv}>
                    <img className={styles.cardIcon} src={require('./img/gaq.png')}/>
                    <div className={styles.cardTitle}>便捷性</div>
                    <div className={styles.cardDetail}>
                        用户无需亲自前往老挝，即可通过软件平台浏览地产资料，大大提高了看地的便捷性。
                        一键咨询与预约：用户可以直接在软件上联系经纪人或卖家，进行在线咨询和预约看地，简化了交易流程。
                    </div>
                </div>
                <div className={styles.cardDiv}>
                    <img className={styles.cardIcon} src={require('./img/yyy.png')}/>
                    <div className={styles.cardTitle}>专业团队</div>
                    <div className={styles.cardDetail}>有专业的团队提供技术支持和客户服务，确保用户在使用过程中遇到的问题能够得到及时解决。
                    </div>
                </div>
                <div className={styles.cardDiv}>
                    <img className={styles.cardIcon} src={require('./img/gxh.png')}/>
                    <div className={styles.cardTitle}>一站式服务</div>
                    <div className={styles.cardDetail}>从地源搜索、看地预约、合同签订到售后服务，软件提供了一站式的解决方案，降低了用户的交易成本和时间成本。</div>
                </div>
                <div className={styles.cardDiv}>
                    <img className={styles.cardIcon} src={require('./img/fwy.png')}/>
                    <div className={styles.cardTitle}>个性化推荐</div>
                    <div className={styles.cardDetail}>根据用户的浏览历史和偏好，软件会提供个性化的地源推荐，提高用户的满意度和粘性。</div>
                </div>
            </div>
        </div>
    );
}
