import styles from './index.module.scss';
import classNames from 'classnames';
import React from 'react';


interface Props {
    className?: string;
    style?: React.CSSProperties;
    onFreeClick: () => void;//点击免费试用
    onButtonClick: (id: string) => void;//点击按钮
    isFixedDiv?: boolean;
    onGoTop: () => void;//返回顶部
}

export default function NavHeader({
                                      className, style, onFreeClick, onButtonClick, isFixedDiv, onGoTop
                                  }: Props) {
    return (
        <div className={classNames(className, styles.headerDiv)} style={style}>
            <div className={styles.header}>
                {!isFixedDiv && <img className={styles.logo} src={require('./img/logo.png')}/>}
                {isFixedDiv &&
                    <img className={styles.logo} src={require('./img/fixedLogo.png')} onClick={() => onGoTop()}/>}
                <div className={styles.navDiv}>
                    <div className={isFixedDiv ? styles.fixedNavButton : styles.navButton}
                         onClick={() => onButtonClick('Wrap')}>服务
                    </div>
                    <div className={isFixedDiv ? styles.fixedNavButton : styles.navButton}
                         onClick={() => onButtonClick('Solution')}>产品介绍
                    </div>
                    <div className={isFixedDiv ? styles.fixedNavButton : styles.navButton}
                         onClick={() => onButtonClick('ProductGoodness')}>产品优势
                    </div>
                    <div className={isFixedDiv ? styles.fixedNavButton : styles.navButton}
                         onClick={() => onButtonClick('About')}>关于
                    </div>
                    <div className={isFixedDiv ? styles.fixedNavButton : styles.navButton}
                         onClick={() => onButtonClick('Footer')}>联系
                    </div>
                    {/*<div className={isFixedDiv?styles.fixedFreeButton:styles.freeButton} onClick={onFreeClick}>免费试用</div>*/}
                </div>
            </div>
        </div>
    );
}
