import styles from './index.module.scss';
import React from 'react';

interface Props {
    onButtonClick: (id: string) => void; //点击按钮
}

export default function Footer({onButtonClick}: Props) {
    return (
        <div>
            <div className={styles.Footer} id="Footer">
                <div className={styles.footerContent}>
                    <div className={styles.lineDiv}>
                        <img
                            className={styles.footerLogo}
                            src={require('./img/logo.png')}
                        />

                    </div>
                    <div className={styles.lineDiv}>
                        <div className={styles.title}>网站栏目</div>
                        <div className={styles.buttonDiv}>
                            <div
                                className={styles.button}
                                onClick={() => onButtonClick('Wrap')}>
                                服务
                            </div>

                            <div
                                className={styles.button}
                                onClick={() => onButtonClick('About')}>
                                关于
                            </div>

                            <div
                                className={styles.button}
                                onClick={() => onButtonClick('Solution')}>
                                产品介绍
                            </div>

                            <div
                                className={styles.button}
                                onClick={() => onButtonClick('Footer')}>
                                联系方式
                            </div>

                            <div
                                className={styles.button}
                                onClick={() =>
                                    onButtonClick('ProductGoodness')
                                }>
                                产品优势
                            </div>
                        </div>
                    </div>
                    <div className={styles.lineDiv}>
                        <div className={styles.title}>服务与支持</div>
                        <div className={styles.subFont}>
                            邮箱：help@sukusukulao.com
                        </div>
                        <div className={styles.subFont}>
                            地址：四川省成都市青羊区御西街8号
                        </div>

                        <div className={styles.subFont}>
                            工作时间：8:00 - 17:00（工作日）
                        </div>
                    </div>

                    <div className={styles.qrLineDiv}>
                        <div className={styles.phoneTitle}>电话咨询</div>
                        <div className={styles.phone}>028-86744444</div>
                    </div>
                </div>
            </div>

            <div className={styles.copyRight}>
                <a href="https://beian.miit.gov.cn">
                    蜀ICP备2024089083号-1
                </a>
                Copyright © www.sukusukulao.com, All Rights Reserved.
                四川励尚数字传媒技术有限公司
            </div>
        </div>
    );
}
