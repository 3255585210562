import styles from './index.module.scss';
import React from 'react';

interface Props {
    gotoTop:() => void;
}

export default function FixedButton({gotoTop}:Props) {
    return (
        <div className={styles.fixedDiv}>
            <div className={styles.buttonDiv}>
                <div className={styles.returnTop} onClick={() => gotoTop()}>
                    <div className={styles.returnDiv}>
                        <img className={styles.returnTopIcon} src={require('./img/returnTopIcon.png')}/>
                    </div>
                    <div className={styles.font} >
                        返回顶部
                    </div>
                </div>

            </div>
        </div>
    );
}
