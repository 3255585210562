import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import 'antd/dist/antd.variable.min.css';
import Home from './Pages/Home';
import {ConfigProvider} from "antd";


ConfigProvider.config({
    theme: {
        primaryColor: '#F06109',
    }
})

function App() {
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </BrowserRouter>

    );
}

export default App;
