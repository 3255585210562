import styles from './index.module.scss';
import NavHeader from './Components/NavHeader';
import Wrap from './Components/Wrap';
import Solution from './Components/Solution';
import ProductGoodness from './Components/ProductGoodness';
import About from './Components/About';
import Footer from './Components/Footer';
import {useState} from 'react';
import FixedButton from './Components/FixedButton';
import DialogForm from './Components/DialogForm/DialogForm';
import {Modal} from 'antd';

export default function Home() {
    const [isFixedDiv, setIsFixedDiv] = useState(false);
    const [isShowDialog, setIsShowDialog] = useState(false);

    function gotoTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    window.addEventListener('scroll', () => {
        let wrap = document.getElementById('Wrap');
        // document.documentElement.scrollTop >700?setIsFixedDiv(true):setIsFixedDiv(false);
        if (wrap) {
            document.documentElement.scrollTop > wrap.offsetTop
                ? setIsFixedDiv(true)
                : setIsFixedDiv(false);
        }
    });

    //点击免费试用弹窗
    function onFreeClick() {
        // setIsShowDialog(true);
        Modal.success({
            title: '申请试用',
            content: '请拨打电话 15904253500',
        });
    }

    //点击按钮跳转锚点
    function onButtonClick(id: string) {
        let wrap = document.getElementById(id);
        if (wrap) {
            window.scroll({
                top: wrap.offsetTop - 100,
            });
        }
    }

    function close() {
        setIsShowDialog(false);
    }

    return (
        <div className={styles.root} id="root">
            <div className={styles.pc}>
                {/*pc头部start*/}
                <div className={styles.topDiv}>
                    <div className={styles.greenBg}>
                        <div className={styles.imgDiv}>
                            <img
                                className={styles.topBg}
                                src={require('./img/carousel1.png')}
                            />

                            <div className={styles.text1}>
                                一站式解决<br/>
                                国际土地流转业务
                            </div>

                            <div className={styles.qrcode}>
                                <img src={require("./img/qrcode.png")}/>
                            </div>
                        </div>
                    </div>

                    <div className={styles.blueBg}>
                        <div className={styles.imgDiv}>
                            <img
                                className={styles.topBg2}
                                src={require('./img/carousel2.png')}
                            />

                            <div className={styles.text2}>
                                专业深耕&nbsp;&nbsp;地产领航

                                <div className={styles.text3}>
                                    智慧投资未来&nbsp;&nbsp;&nbsp;&nbsp;携手共创财富梦
                                </div>
                            </div>

                        </div>
                    </div>

                    <NavHeader
                        onFreeClick={onFreeClick}
                        onButtonClick={onButtonClick}
                        onGoTop={gotoTop}
                    />
                </div>

                {isFixedDiv && (
                    <NavHeader
                        isFixedDiv={isFixedDiv}
                        onFreeClick={onFreeClick}
                        onButtonClick={onButtonClick}
                        className={styles.fixedHeader}
                        onGoTop={gotoTop}
                    />
                )}
                {/*pc四个卡牌效果start*/}
                <Wrap />
                {/*产品介绍start*/}
                <Solution />
                {/*产品优势start*/}
                <ProductGoodness />
                {/*关于start*/}
                <About />
                {/*footer*/}
                <Footer onButtonClick={onButtonClick} />

                {/*浮动回到顶部、二维码*/}
                {isFixedDiv && <FixedButton gotoTop={gotoTop} />}

                {/*弹窗填信息*/}
                <DialogForm show={isShowDialog} onClose={close} />
            </div>
        </div>
    );
}
