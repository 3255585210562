import styles from './index.module.scss';
import React from 'react';
import {Button, Form, Input, message} from 'antd';
import {useForm} from 'antd/es/form/Form';
import useRequest from '../../../../Utils/Hooks/useRequest';
import {addCustomerContactInfo} from '../../../../Services/main';
import useRequestFn from '../../../../Utils/Hooks/useRequestFn';
import {errorMessage} from '../../../../Utils/common';
import TextArea from 'antd/es/input/TextArea';

interface Props {
    show: boolean;
    onClose: () => void;
}

export default function DialogForm({show, onClose}: Props) {
    const [form] = useForm();
    const formRules = {
        userName: {required: true, message: `姓名不能为空`},
        companyName: {required: true, message: `学校名称不能为空`},
        phone: {
            required: true,
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: '手机号长度11位，以13/14/15/16/17/18/19开头',
        },
    };
    const [save, {loading}] = useRequestFn(addCustomerContactInfo);

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 18, offset: 1},
    };

    async function onFinish(val: any) {
        try {
            await save({...val});
            message.success('提交成功！');
            onClose();
        } catch (e) {
            message.error(errorMessage(e));
        }
    }

    return (
        <div className={styles.root} style={{display: show ? 'block' : 'none'}}>
            <div className={styles.wrapper}>
                <div className={styles.dialogForm}>
                    <img
                        className={styles.close}
                        src={require('./img/close.png')}
                        onClick={() => onClose()}
                    />
                    <img
                        className={styles.dialogHeader}
                        src={require('./img/dialogHeader.png')}
                    />
                    <Form
                        form={form}
                        onFinish={onFinish}
                        className={styles.form}
                        labelCol={layout.labelCol}
                        wrapperCol={layout.wrapperCol}
                        scrollToFirstError
                        autoComplete="off">
                        <Form.Item
                            name="companyName"
                            label={`学校名称：`}
                            rules={[formRules.companyName]}
                            initialValue={''}>
                            <Input
                                placeholder={`请输入学校名称`}
                                maxLength={50}
                            />
                        </Form.Item>
                        <Form.Item
                            name="userName"
                            label={`联系人姓名：`}
                            rules={[formRules.userName]}
                            initialValue={''}>
                            <Input
                                placeholder={`请输入联系人姓名`}
                                maxLength={20}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label={`联系人电话：`}
                            rules={[formRules.phone]}
                            initialValue={''}>
                            <Input
                                placeholder={`请输入联系人电话`}
                                maxLength={11}
                            />
                        </Form.Item>
                        <Form.Item
                            name="info"
                            label={`备注：`}
                            initialValue={''}>
                            <TextArea
                                rows={4}
                                placeholder={`您可以备注您方便接听电话的时间段，我们会在您方便的时候给您电话。`}
                                maxLength={100}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 11, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}
