import styles from './index.module.scss';
import React from 'react';

export default function About() {
    return (
        <div className={styles.About} id="About">
            <div className={styles.aboutTitle}>
                <img
                    className={styles.aboutImg}
                    src={require('./img/about.png')}
                />
            </div>
            <div className={styles.flexDiv}>
                <div className={styles.leftDiv}>
                    <div className={styles.rowFont}>
                        四川励尚数字传媒技术有限公司借助先进的技术手段，收集并整合了海量的土地信息、市场数据、政策动态等多维度资源，通过智能算法进行深度挖掘与分析，在Suku
                        Suku上为您精准识别出那些具有极高潜力和价值的优质精品土地，在拿地过程中，全程陪伴您左右，提供一站式解决方案。我们始终秉持着“快速低成本”的服务理念，让您在享受高品质服务的同时，也能感受到实实在在的利益回报。

                    </div>

                    <div className={styles.rowFont}>

                        我们拥有专业的团队、先进的技术和丰富的经验，将全力以赴为您解决各种难题，助您快速低成本拿到优质精品土地。让我们携手并进，共创辉煌未来。
                    </div>
                </div>
                <div className={styles.rightDiv}>
                    <img
                        className={styles.rightImg}
                        src={require('./img/aboutImg.png')}
                    />
                </div>
            </div>
        </div>
    );
}
