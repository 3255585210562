import styles from './index.module.scss';
import React from 'react';


export default function Wrap() {
    return (

        <div>
            <div className={styles.solTitle}>
                我们的服务
            </div>

            <div className={styles.wrap} id='Wrap'>
                <div className={styles.box1}>
                    <div className={styles.box}>
                        <div className={styles.boxTitle}>数字营销策略制定</div>
                        <div className={styles.detail}>通过深入研究目标市场、消费者行为及数据分析，为企业量身定制高效、创新的数字化营销方案，以驱动业务增长和品牌提升。
                        </div>
                    </div>
                </div>
                <div className={styles.box1}>
                    <div className={styles.box}>
                        <div className={styles.boxTitle}>网站设计与开发</div>
                        <div
                            className={styles.detail}>专注于创建用户友好、功能丰富且视觉吸引的在线平台，旨在提升用户体验、增强品牌形象并促进业务目标的有效实现。
                        </div>
                    </div>
                </div>
                <div className={styles.box1}>
                    <div className={styles.box}>
                        <div className={styles.boxTitle}>移动应用开发</div>
                        <div
                            className={styles.detail}>专注于设计流畅的用户体验、地产服务的多样化需求的移动应用，不断探索和应用最新的技术成果，如人工智能、大数据、云计算、区块链等，以提升服务的智能化水平、增强用户体验并优化运营效率。
                        </div>
                    </div>
                </div>
                <div className={styles.box1}>
                    <div className={styles.box}>
                        <div className={styles.boxTitle}>品牌推广与管理</div>
                        <div
                            className={styles.detail}>通过策略性营销活动、媒体传播、客户关系维护及市场反馈分析等手段，提升品牌形象、增强品牌认知度与忠诚度，并有效管理品牌声誉与价值的综合过程。
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
